<template>
  <fw-layout :back-to-enable="false" mobile-ready>
    <template #main-content>
      <fw-heading size="h1" :counter="icons.length"><span class="text-gray-500">Fw UI</span> / Icons</fw-heading>
      <div class="flex flex-wrap gap-3 my-10">
        <div
          v-for="icon in icons"
          :key="icon"
          class="w-28 flex flex-col gap-1 text-xs items-center text-center justify-center bg-white rounded py-2 px-1"
        >
          <div class="flex items-center">
            <component :is="icon" class="w-10 h-10" />
          </div>
          <div class="font-mono flex-1 text-gray-500">
            {{ icon }}
          </div>
        </div>
      </div>
    </template>
  </fw-layout>
</template>

<script>
export default {
  name: 'UiIcons',
  data() {
    return {
      icons: [],
    }
  },
  mounted() {
    this.loadIcons()
  },
  methods: {
    loadIcons() {
      // List all files .vue inside @/fw-modules/fw-core-vue/ui/components/icons
      const icons = require.context('@/fw-modules/fw-core-vue/ui/components/icons', true, /\.vue$/)
      icons.keys().forEach(key => {
        const icon = icons(key)
        // Convert icon name (icon.default.name) to kebab-case
        const kebabCaseName = icon.default.name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
        this.icons.push(kebabCaseName)
      })
    },
  },
}
</script>
